import { Injectable, WritableSignal, computed, signal } from "@angular/core";
import {
	getCookie,
	setCookie,
} from "@sportyano/core/common/utils/cookies-controller";

interface IPlayerState {
	id: number;
	is_interest_shown: boolean;
}
interface IApplicationState {
	players: Array<IPlayerState>;
}

const initialState: IApplicationState = {
	players: [],
};

@Injectable({
	providedIn: "root",
})
export class StateManagementService {
	private _stateStore: WritableSignal<IApplicationState> =
		signal<IApplicationState>(initialState);
	readonly stateStore = this._stateStore;

	public get getStateStore(): WritableSignal<IApplicationState> {
		return this._stateStore;
	}

	public get getSessionStore(): Record<string, any> {
		return this._getSessionStorageItems();
	}

	constructor() {}
	public setPlayerState(player: IPlayerState): void {
		// Retrieve current players from session storage, defaulting to an empty array if none exist
		const storedPlayers = this.getSessionStorage("players") || [];
	  
		// Get the current players from the state store
		const currentPlayers = this._stateStore().players || [];
	  
		// Combine the players from both session storage and state store, avoiding duplicates
		const allPlayers = [...storedPlayers, ...currentPlayers];
	  
		// Filter out any players with the same ID and add the new one
		const updatedPlayers = allPlayers.filter((p) => p.id !== player.id);
	  
		// Add the new player
		updatedPlayers.push(player);
	  
		// Remove duplicates by ID after pushing the new player
		const uniquePlayers = updatedPlayers.filter(
		  (player, index, self) =>
			index === self.findIndex((p) => p.id === player.id)
		);
	  
		// Update the state with unique players
		this._stateStore.update((s) => ({
		  ...s,
		  players: uniquePlayers,
		}));
	  
		// Save the unique players to session storage
		this.setSessionStorage("players", uniquePlayers);
	  }
	  
	  

	public setSessionStorage(key: string, value: unknown) {
		sessionStorage.setItem(key, JSON.stringify(value));
	}
	public getSessionStorage(key: string) {
		const state = sessionStorage.getItem(key);
		return state ? JSON.parse(state) : null;
	}

	public deleteSessionStorage(key: string): void {
		sessionStorage.removeItem(key);
	}

	public setCookie(key: string, value: unknown) {
		setCookie(key, JSON.stringify(value));
	}
	public getCookie(key: string) {
		return getCookie(key);
	}

	private _getSessionStorageItems(): Record<string, any> {
		const sessionData: Record<string, any> = {};
		for (let i = 0, len = sessionStorage.length; i < len; i++) {
			const key = sessionStorage.key(i);
			if (key) {
				const value = sessionStorage.getItem(key);
				sessionData[key] = this.safeParseJSON(value);
			}
		}
		return sessionData;
	}

	private safeParseJSON(value: string | null): any {
		try {
			return value ? JSON.parse(value) : value;
		} catch {
			return value;
		}
	}
}
